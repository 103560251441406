<template>
  <div class="high" style="display: inline-grid">
    <base-select
      :label="label"
      :value="pointCurrent"
      :option-list="pointList"
      @change="handleChange($event.target.value)"
    />
  </div>
</template>

<script setup>
import { request } from "@/components-js/requestSrv";
import BaseSelect from "@/components/BaseSelect";
import { onBeforeMount, ref, watch, toRef, onMounted } from "vue";
const emits = defineEmits(["change"]);

const props = defineProps({
  label: String,
  id_pointIn: [Number, String],
  allPointsFlag: Boolean,
  disabled: String,
});

const pointList = ref(props.allPointsFlag ? [{ id: "%", nickname: "Все точки" }] : []);
const pointCurrent = ref(props.id_pointIn);

const handleChange = value => {
  emits("change", (pointList.value?.find(item => item.id.toString() === value.toString())).id);
};

watch(toRef(props, "id_pointIn"), newVal => {
  pointCurrent.value = newVal;
});

onBeforeMount(async () => {
  pointList.value = [...pointList.value, ...(await request("/api/point/list", "GET"))];
});

// onMounted(() => (pointCurrent.value = toRef(props, "id_pointIn")));
</script>
<style lang="scss" scoped>
.point-selector {
  margin-top: 12px;
  padding-left: 10px;

  @media (max-width: 980px) {
    margin-top: 6px;

    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
  }
}
</style>
